import User from "~/modules/user/services/User.js";
import { Model } from '@vuex-orm/core'

export default class Rental extends Model {
  static entity = 'rental'
  static apiConfig = {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
    // baseURL: '/app_dev.php',
    dataKey: 'rentals',
    // actions: {
    //   fetch: {
    //     method: 'get',
    //     url: 'billings'
    //   }
    // }
  }

  static fields () {
    return {
      id: this.attr(null),
      updateStatusLocation: this.attr(null),
      bank_wire: this.attr(null),
      Termined: this.attr(null),
      typeAnnulation: this.attr(null),
      AcceptedAt: this.attr(null),
      PaidAt: this.attr(null),
      CanceledAt: this.attr(null),
      cancelDifferenceDays: this.attr(null),
      created: this.attr(''),
      chatLocation: this.attr([]),
      assuranceHt: this.attr(0),
      assurance_ht: this.attr(''),
      ownerRevenuTTC: this.attr(''),
      ownerRevenuHT: this.attr(''),
      totalTTC: this.attr(''),
      departUnix: this.attr(0),
      finUnix: this.attr(0),
      paidAtUnix: this.attr(null),
      feesInvoice: this.attr(false),
      ownerInvoice: this.attr(false),
      acceptedAtUnix: this.attr(null),
      canceled: this.attr(false),
      sinistre: this.attr(false),
      sinisterLink: this.attr(''),
      statutProprietaire: this.attr(''),
      statutLocataire: this.attr(''),
      frais_service_ht: this.attr(''),
      frais_service_ttc: this.attr(''),
      leftToPay: this.attr(''),
      caution: this.attr(''),
      amountPaid: this.attr(''),
      prix_location_ht: this.attr(''),
      prix_location_ttc: this.attr(''),
      prixLocationTtc: this.attr(''),
      prix_total_ht: this.attr(''),
      prix_total_ttc: this.attr(''),
      service_total_ht: this.attr(''),
      service_total_ttc: this.attr(''),
      titreLocation: this.attr(''),
      remboursement: this.attr(null),
      titre_location: this.attr(''),
      chatRoomId: this.attr(''),
      panier: this.attr({}),
      souscategorieannonce: this.attr({}),
      paymentLessThen3Hours: this.attr(null),
      dateIntervalAfterPayment: this.attr(null),
      // facture: this.belongsTo(''),
      numeroLocation: this.attr(''),
      numero_location: this.attr(''),
      ownerBillingId: this.attr(''),
      tenantId: this.attr(''),
      ownerId: this.attr(''),
      billId: this.attr(''),
      ownerFeesTtc: this.attr(0),
      price_before_promo: this.attr(null),
      description: this.attr(''),
      depart: this.attr(''),
      locataire: this.belongsTo(User, 'tenantId'),
      proprietaire: this.belongsTo(User, 'ownerId'),
      locationProblem: this.attr(null),
      avisDone: this.attr([]),
    }
  }
}